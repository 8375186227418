import React from 'react';
import { graphql } from 'gatsby'; 

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import "./single-post.scss"
import Tags from '../../components/tags';

const SinglePost = ({ data }) => {
    const post = data.markdownRemark.frontmatter;

    return (
        <Layout>
            <SEO/>
            <div className="single-post">
              <div className="single-post-header">
                <div className="published">{`published ${post.date}`}</div>
                <h1>{post.title}</h1>
                <Tags tags={post.tags}/>
              </div>
              <div className="article" dangerouslySetInnerHTML={{__html: data.markdownRemark.html}}/>
            </div>
        </Layout>
    )
}

export const postQuery = graphql`
  query blogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } } ) { 
      id
      html
      frontmatter {
        title
        author
        date(formatString: "MMM Do YYYY")
        tags
      }
    }
  }
`

export default SinglePost